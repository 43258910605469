<template>
  <div v-if="!loading" class="bg p-5 justify-content-center align-items-center">
    <div class="responsive-container d-flex justify-content-center">
      <div class="card m-2 custom-card">
        <div class="card-body">
          <div class="img-container mb-3 mt-2">
            <img src="../../../assets/e-presc@2x.svg" />
          </div>
          <h5>
            New to SelCare?
          </h5>
          <p>
            Pretium, ac in nisi, euismod libero, semper ante cras pellentesque.
            Sed urna massa.
          </p>
          <router-link
            class="text-decoration-none"
            to="/e-prescription/register"
          >
            <button
              type="button"
              class="
                btn btn-primary
                button-primary-color-bg
                mb-2
                d-flex
                align-items-center
              "
            >
              <p class="m-0">Register New</p>
              <i class="ri-add-fill ml-2 fs-4"></i>
              <!-- <img class="icon-svg ml-1" src="../../../assets/plus.svg" /> -->
            </button>
          </router-link>
        </div>
      </div>
      <div class="card m-2 custom-card">
        <div class="card-body">
          <div class="img-container mb-3 mt-2">
            <img src="../../../assets/user@2x.svg" />
          </div>
          <h5>Already Selcare user?</h5>
          <p>
            Pretium, ac in nisi, euismod libero, semper ante cras pellentesque.
            Sed urna massa.
          </p>
          <router-link
            class="text-decoration-none"
            :to="`/auth?redirect=${
              this.$route.query.redirect || 'eprescription'
            }`"
          >
            <button
              type="button"
              class="
                btn btn-white
                button-white-color-bg
                align-items-center
                mb-2
                d-flex
              "
            >
              <p class="m-0">Log in</p>
              <i class="ri-login-box-line fs-4 ml-2"></i>
              <!-- <img class="icon-svg ml-1" src="../../../assets/enter.svg" /> -->
            </button>
          </router-link>
        </div>
      </div>
      <!-- <div class="card m-2 custom-card">
        <div class="card-body">
          <div class="img-container mb-3 mt-2">
            <img src="../../../assets/Panel-1.svg" />
          </div>
          <h5>Panel of Selcare</h5>
          <p>
            Pretium, ac in nisi, euismod libero, semper ante cras pellentesque.
            Sed urna massa.
          </p>
          <button
            type="button"
            class="btn align-items-center mb-2 d-flex"
            @click="handlePanelAction"
          >
            <p class="m-0">View panel</p>
            <i class="ri-arrow-right-line fs-4 ml-2"></i>
          </button>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CheckingPage",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["currentCustomer"]),
  },
  methods: {
    handlePanelAction() {
      window.open("https://selcareclinic.com/our-clinics/");
    },
  },
  async mounted() {
    this.loading = true;

    if (!this.$route.query.redirect && this.currentCustomer) {
      this.$router.push({
        path: "/e-prescription/choose-doctors",
      });
    }
    this.loading = false;
  },
};
</script>

<style scoped>
.bg {
  position: relative;
  display: flex;
  height: calc(100vh - 75px);
  background-image: url(../../../assets/Vector.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.button-primary-color-bg {
  background-color: #f97066 !important;
  border-color: #f97066 !important;
}

.button-white-color-bg {
  border-color: #c9c9c9 !important;
}

.custom-card {
  min-width: 300px;
  max-width: 350px;
}

.icon-svg {
  height: 15px;
  width: 15px;
}

.icon-svg img {
  height: auto;
  width: 100%;
}

.img-container {
  height: 100px;
  width: 100px;
}

.img-container img {
  height: 100%;
  width: auto;
}

::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 420px) {
  .responsive-container {
    display: block !important;
  }
  .custom-card {
    min-width: 300px;
    max-width: 350px;
  }
}
</style>